import { t } from 'i18next';
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { calendarFilterTasksPriority } from 'store/modules/task/calendarFilter/calendarFilterTaskActions';
import { useActions } from 'utils/store/useActions';
import { TaskPriority } from 'api/task/taskModel';
import { selectCalendarFilterTasksPriority } from 'store/modules/task/calendarFilter/calendarFilterSelectors';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { PriorityFilterButton } from '../common/priorityFilter/typePriorityFilter';

export const CalendarPriorityFilter: React.FC = () => {
  const isTaskPriorityEnabled = useFeatureState(FeatureFlags.TaskPriority);
  const filterTasksPriority = useSelector(selectCalendarFilterTasksPriority);
  const actions = useActions({ calendarFilterTasksPriority });
  const title = useMemo(()=> {
    switch (filterTasksPriority) {
      case TaskPriority.None:
        return `${t('Show low priority tasks')}`;
      case TaskPriority.Low:
        return `${t('Show medium priority tasks')}`;
      case TaskPriority.Medium:
        return `${t('Show high priority tasks')}`;
      case TaskPriority.High:
        return `${t('All tasks regardless of priority')}`;
    }
  }, [filterTasksPriority])

  const handleFilterTasksPriority = useCallback(() => {
      actions.current.calendarFilterTasksPriority()
  },[actions]);

  if(!isTaskPriorityEnabled) return <></>;

  return (
    <PriorityFilterButton
      title={title}
      onFilterTasksPriority={handleFilterTasksPriority}
      typePriority={filterTasksPriority}
    />
  )
}
