import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { environmentName } from 'utils/globals';
import { Environment } from 'model/environment';
import { fromEnum } from 'utils/helpers/fromEnum';
import { IResponseErrorPayload } from 'model/error';
import { IResponseSuccessPayload } from 'model/success';
import { createStrictSchema, transformFieldName } from 'utils/api/apiValidation';
import { IRawTask } from 'model/task';
import { convertToStringType, requiredStringType } from 'model/dataBasicTypes';
import { attachmentSchema, checklistItemUpdateSchema } from './prototype/prototypeModel';
import { taskTagNewSchema } from './tag/taskTagModel';

export enum TaskStatus {
    Incomplete = 'Incomplete',
    Declined = 'Declined',
    Submitted = 'Submitted',
    Done = 'Done',
}

export const taskStatusRecords = {
    [TaskStatus.Incomplete]: 'Incomplete',
    [TaskStatus.Declined]: 'Declined',
    [TaskStatus.Submitted]: 'Submitted',
    [TaskStatus.Done]: 'Completed',
};

export enum TaskPriority {
    None = 'None',
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
}

export const taskPriorityRecords  = {
    [TaskPriority.None]: 'None',
    [TaskPriority.Low]: '!',
    [TaskPriority.Medium]: '!!',
    [TaskPriority.High]: '!!!',
};

export interface IChecklistItem {
    name: string;
    requiresAttachment: boolean;
    requiresCustomResponse?: boolean;
}

export interface IChangeTaskStatusRequest {
    id: string;
    status: TaskStatus;
}

export interface IChangeTaskStatusSuccess extends IResponseSuccessPayload {
    task: TaskType
}

export interface ITaskUpdateSuccess extends IResponseSuccessPayload {
    task: TaskDetailsType;
}

export interface ICheckListItemUpdatePayload {
    taskId: string;
    checkListItemId: string;
    isCompleted: boolean;
}

export interface IUpdateChecklistSuccess extends ICheckListItemUpdatePayload, IResponseSuccessPayload { }

export interface IChecklistItemUpdateFailed extends ICheckListItemUpdatePayload, IResponseErrorPayload { }

const taskCommonWithoutStatus = {
    id: requiredStringType,
    title: convertToStringType,
    description: convertToStringType,
    dueDate: convertToStringType,
    assignedToCompanyLocationId: convertToStringType,
    createdBy: convertToStringType,
};

const statusType = fromEnum('TaskStatus', TaskStatus);

const taskCommon = {
    ...taskCommonWithoutStatus,
    status: statusType,
};

export const taskRawType = t.type(taskCommon);

export const taskStrictRawType = t.type({
    ...createStrictSchema(taskCommonWithoutStatus),
    status: statusType,
});

export const taskType = tPromise.extendType(taskRawType,
    ({ assignedToCompanyLocationId, ...taskRaw }) => ({
        ...taskRaw,
        updating: false,
        locationId: assignedToCompanyLocationId,
    }),
    ({ locationId, ...values }) => {
        if (environmentName === Environment.Local) {
            throw new Error('Task encoder to task raw should not be called');
        }
        return {
            ...values,
            assignedToCompanyLocationId: locationId,
        };
    });

export const checklistItem = t.type({
    id: t.string,
    name: t.string,
    order: t.number,
    isCompleted: t.boolean,
});

export const attachment = t.type({
    fileId: t.string,
    name: t.string,
    size: t.union([t.undefined, t.string]),
});

export const taskDetails = t.type({
    ...taskCommon,
    createdBy: t.string,
    checklist: t.array(checklistItem),
    attachments: t.array(attachment),
});

export type TaskType = t.TypeOf<typeof taskType>;
export type TaskDetailsType = t.TypeOf<typeof taskDetails>;
export type TaskChecklistItemType = t.TypeOf<typeof checklistItem>;
export type TaskAttachmentType = t.TypeOf<typeof attachment>;

export function tasksErrorReporter(errors: t.Errors) {
    return errors.map(error => {
        const taskInfo = error.context[0].actual as IRawTask;
        const fieldName = error.context[1].key;
        let identifierSubstring = '';
        if (taskInfo) {
            if (taskInfo.title) {
                identifierSubstring = taskInfo.title + ' ';
            } else if (taskInfo.id) {
                identifierSubstring = taskInfo.id + ' ';
            }
        }

        return `The task ${identifierSubstring}has no ${transformFieldName(fieldName)} field specified. Please contact your Company administrator`;
    });
}

export enum TaskRecurrence {
    Daily = 'Daily',
    EveryWeekday = 'EveryWeekday',
    Weekly = 'Weekly',
    BiWeekly = 'BiWeekly',
    Monthly = 'Monthly',
    MonthlyByDay = 'MonthlyByDay',
    Annually = 'Annually',
}

export const recurrenceTypeSchema = fromEnum('TaskRecurrence', TaskRecurrence);

export const createTaskSchema = t.type({
    title: t.string,
    publishDate: t.string,
    recurrence: t.union([t.null, recurrenceTypeSchema]),
    dueDate: t.string,
    description: t.string,
    checklist: t.array(checklistItemUpdateSchema),
    selectedCompanyLocations: t.union([t.undefined, t.array(t.string)]),
    attachments: t.array(attachmentSchema),
    watchers: t.union([t.undefined, t.array(t.string)]),
    isExpirable: t.boolean,
    tag: t.union([t.null, taskTagNewSchema]),
    createdBy: t.string,
    locationIds: t.union([t.undefined, t.array(t.string)]),
    id: t.union([t.undefined, t.string]),
    sendReminder: t.union([t.undefined, t.boolean]),
    priority: t.union([t.undefined, fromEnum('TaskPriority', TaskPriority)])
});


export const editTaskSchema = t.type({
    title: t.string,
    description: t.string,
    dueDate: t.string,
    checklistItems: t.array(checklistItemUpdateSchema),
    locationIds: t.array(t.string),
    attachments: t.array(attachmentSchema),
    watchers: t.union([t.undefined, t.array(t.string)]),
    isExpirable: t.boolean,
    tag: t.union([t.null, taskTagNewSchema]),
    createdBy: t.string,
    sendReminder: t.union([t.undefined, t.boolean]),
    priority: t.union([t.undefined, fromEnum('TaskPriority', TaskPriority)])
});

export const createLabelSchema = t.type({
    name: t.string,
    color: t.string
});

export type TaskCreateRequest = t.TypeOf<typeof createTaskSchema>;
export type TaskEditRequest = t.TypeOf<typeof editTaskSchema>

export type TaskCreateResult = {
    id: string;
    isScheduled: boolean;
}

export type LabelCreateRequest = t.TypeOf<typeof createLabelSchema>;

export type LabelCreateResult = {
    name: string;
    color: string;
}
