import React, { PropsWithChildren } from 'react';
import {
    Box,
    styled,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CellProps } from 'react-table';
import { IStoreTaskGridRowData } from '../model';
import { fontColorMap, textMap } from '../../../../model';
import { useStoreTaskSidebar } from 'modules/task/taskList/hooks/useStoreTaskSidebar';
import { IEntity } from 'model/entity';
import { ApprovableIcon } from 'modules/task/taskList/components/ApprovableIcon';
import { ExpireIcon } from 'modules/task/taskList/components/ExpireIcon';
import { CreateCSSProperties } from '@material-ui/styles';
import { TaskTagType } from 'api/task/tag/taskTagModel';
import { TagBoxColorTooltip } from 'modules/task/common/taglist/TagBoxColorTooltip';
import { PriorityTask, PriorityTaskProps } from 'modules/task/taskList/components/sharedCells/TaskName';

const commonStyles: CreateCSSProperties<{}> = {
    letterSpacing: '0.15px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 400,
    fontFamily: `'Inter', 'Arial', sans-serif`,
    textAlign: 'left',
};

const StyledTaskNameText = styled(Typography)(({ theme }) => ({
    fontSize: '14px !important',
    lineHeight: '20.02px !important',
    color: theme.palette.text.primary,
    ...commonStyles,
}));

const StyledTaskNameTextMobile = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    lineHeight: '15.73px',
    color: theme.palette.text.primary,
    ...commonStyles,
}));

const PointerBox = styled(Box)({ cursor: 'pointer' });

const TaskNameWrapper: React.FC<PropsWithChildren<IEntity>> = ({ id, children }) => {
    const { openStoreTaskSidebar } = useStoreTaskSidebar(id);

    return (
        <PointerBox onClick={openStoreTaskSidebar}>
            {children}
        </PointerBox>
    );
};

type TaskNameCoreProps = PriorityTaskProps & {
    taskId: string;
    title: string;
    isApprovable: boolean;
    isExpirable: boolean;
    tag: null | TaskTagType;
    TextWrapper: typeof StyledTaskNameText | typeof StyledTaskNameTextMobile;
};

const TaskNameCore: React.FC<TaskNameCoreProps> = ({ taskId, title, isApprovable, isExpirable, tag = null, TextWrapper, priority }) => (
    <Box
        width="100%"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
    >
        <PriorityTask priority={priority} />
        {tag && 
            <Box position="absolute" style={{left: '12px'}}>
                <TagBoxColorTooltip tag={tag} />
            </Box>
        }
        <Box
            maxWidth="90%"
            component="div"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
        >
            <TaskNameWrapper id={taskId}>
                <TextWrapper>
                    <Tooltip title={title} placement="bottom-start">
                        <span>{title}</span>
                    </Tooltip>
                </TextWrapper>
            </TaskNameWrapper>
        </Box>
        {isApprovable && (
            <Box display="inline-block">
                <ApprovableIcon/>
            </Box>
        )}
        {isExpirable && (
            <Box display="inline-block">
                <ExpireIcon /> 
            </Box>
        )}
    </Box>
);

export const TaskNameCell: React.FC<CellProps<IStoreTaskGridRowData, string>> = props => {
    if (props.row.original.isSkeleton) {
        return <Skeleton width="200px"/>;
    }

    const { id: taskId, approvable = false, expire = false, tag = null, priority } = props.row.original;

    return (
        <TaskNameCore
            taskId={taskId}
            title={props.value}
            isApprovable={approvable}
            isExpirable={expire}
            TextWrapper={StyledTaskNameText}
            tag={tag}
            priority={priority}
        />
    );
};

const StyledCompletionState = styled(Box)({
    fontFamily: `'Inter', 'Arial', sans-serif`,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '1px',
    fontWeight: 700,
    textTransform: 'uppercase',
});

export const TaskNameMobileCell: React.FC<CellProps<IStoreTaskGridRowData, string>> = ({ row, value }) => {
    const { isSkeleton, id: taskId, completionState = 'default', approvable = false, expire = false, tag = null, priority } = row.original;
    const statusText = textMap[completionState];
    const statusColor = fontColorMap[completionState];
    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
        >
            {isSkeleton ? (
                <>
                    <Skeleton width="200px" height="16px"/>
                    <Skeleton width="160px" height="12px"/>
                </>
            ) : (
                <>
                    <TaskNameCore
                        taskId={taskId}
                        title={value}
                        isApprovable={approvable}
                        isExpirable={expire}
                        TextWrapper={StyledTaskNameTextMobile}
                        tag={tag}
                        priority={priority}
                    />
                    <StyledCompletionState color={statusColor}>
                        {statusText}
                    </StyledCompletionState>
                </>
            )}
        </Box>
    );
};

