import * as t from 'io-ts';
import {
    addPriorityTask,
    DisplayedTaskStatus,
    locationTaskStatus,
    storeTaskStatus,
    submittedTasksSchemaType,
    taskStatusSchema,
} from '../common/taskCommonModel';
import { parentTaskShortSchema } from '../parentTask/parentTaskModel';

export const taskStoreStatusDisplay = {
    [DisplayedTaskStatus.Default]: 'Incomplete',
    [DisplayedTaskStatus.Completed]: 'Completed',
    [DisplayedTaskStatus.Overdue]: 'Overdue',
};

export const parentTaskDetailsSchema = t.intersection([
    parentTaskShortSchema,
    t.type({
        locationId: t.string,
        locationName: t.string,
        isCompleted: t.boolean,
        isOverdue: t.boolean,
        isExpired: t.union([t.boolean, t.undefined]),
        isExpirable: t.union([t.boolean, t.undefined]),
        createdAt: t.string,
    }),
    locationTaskStatus,
    addPriorityTask
]);

export const childTaskSchema = t.type({
    id: t.string,
    locationId: t.string,
    locationName: t.string,
    isOverdue: t.boolean,
    isExpired: t.union([t.boolean, t.undefined]),
    isExpirable: t.union([t.boolean, t.undefined]),
});

const childLocationOnlyFieldsSchema = t.type({
    completed: t.number,
    overdue: t.number,
    storeTasks: t.number,
    isCompleted: t.boolean,
});

export const childLocationTaskSchema = t.intersection([
    submittedTasksSchemaType,
    childTaskSchema,
    childLocationOnlyFieldsSchema,
    locationTaskStatus,
    addPriorityTask
]);

export const childStoreTaskSchema = t.intersection([
    childTaskSchema,
    taskStatusSchema,
    storeTaskStatus,
    addPriorityTask
]);

export const parentTaskChildSchema = t.union([childLocationTaskSchema, childStoreTaskSchema]);

export const parentTaskChildrenSchema = t.array(parentTaskChildSchema);

export type ParentTaskDetailsModel = t.TypeOf<typeof parentTaskDetailsSchema>;
export type ParentTaskChildModel = t.TypeOf<typeof parentTaskChildSchema>;
export type ChildStoreTask = t.TypeOf<typeof childStoreTaskSchema>;
export type ChildLocationTask = t.TypeOf<typeof childLocationTaskSchema>

export interface IParentTaskChildren {
    parentId: string;
    children: Array<ParentTaskChildModel>;
}
