import { Box } from '@material-ui/core';
import { useFormStyles } from 'modules/shared/components/formStyles';
import { FormikHandlers } from 'formik';
import { TextInput } from 'components/basicInputs/textInput/TextInput';
import { MarkdownInput } from 'components/basicInputs/MarkdownInput';
import { TASK_DATE_FORMAT, TASK_DATE_FORMAT_INIT, TASK_MAX_TITLE_LENGTH } from 'modules/task/common/form/validation';
import { DateInput } from 'components/basicInputs/dateInput/DateInput';
import { AttachmentsEdit } from '../attachments/Attachments/AttachmentsEdit';
import { TaskLocationsView } from '../locations/TaskLocationsView';
import { FormActions } from 'modules/shared/components/FormActions';
import { useFeatureState } from '../../../utils/hooks/useFeatureState';
import { FeatureFlags } from '../../../utils/featureFlags';
import { TaskTaglist } from '../common/taglist/TaskTaglist';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { TitleForm } from '../common/titleForm';
import { TaskPriority } from '../common/TaskPriority';

export type EditEventFormProps = {
    isUpdating: boolean;
    handleSubmit: FormikHandlers['handleSubmit'];
};

export function EditEventForm({ isUpdating, handleSubmit }: EditEventFormProps) {
    const classes = useFormStyles([]);
    const isTagEnabled = useFeatureState(FeatureFlags.TaskTags);

    return (
        <form onSubmit={handleSubmit}>
            <TitleForm
                title={<Trans>Edit Calendar Event</Trans>}
                tagList={isTagEnabled && <TaskTaglist nameField="tag" />}
                isApproval={false}
            />
            <Box className={classes.formInputs}>
                <TextInput
                    classes={{ root: classes.titleInput }}
                    name="title"
                    label={`${t('Calendar Event Name')}`}
                    showCounter
                    maxLength={TASK_MAX_TITLE_LENGTH}
                    startAdornment={<TaskPriority />}
                />

                <Box className={classes.dateWrapper}>
                    <DateInput
                        name="eventStart"
                        label={`${t('Start Date')}`}
                        valueFormat={TASK_DATE_FORMAT_INIT}
                        minDate={new Date().toString()}
                    />
                </Box>

                <Box className={classes.recurrenceWrapper}>
                    <DateInput
                        name="eventEnd"
                        label={`${t('End Date')}`}
                        valueFormat={TASK_DATE_FORMAT}
                        minDate={new Date().toString()}
                    />
                </Box>
                <MarkdownInput
                    name="description"
                    label={`${t('Description (optional)')}`}
                    classes={{ root: classes.descriptionInput }}
                />

                <AttachmentsEdit labelText={`${t('Calendar Event Creator Attachments')}`}/>

                <TaskLocationsView fieldName='locations' />

            </Box>

            <FormActions isInProgress={isUpdating} />
        </form>
    );
}
