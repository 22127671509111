import { Box, Tooltip } from '@material-ui/core';
import { TaskTagType } from 'api/task/tag/taskTagModel';
import { StyledLink } from 'modules/shared/components/StyledTable';
import { TagBoxColorTooltip } from 'modules/task/common/taglist/TagBoxColorTooltip';
import { ApprovableIcon } from '../ApprovableIcon';
import { ExpireIcon } from '../ExpireIcon';
import { TaskPriority } from 'api/task/taskModel';
import { useMemo } from 'react';
import { t } from 'i18next';
import { PriorityFilterButton } from 'modules/task/common/taskFilter/filters/common/priorityFilter/typePriorityFilter';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';

export type PriorityTaskProps = { 
    priority: TaskPriority
}

export type TaskNameCoreProps = PriorityTaskProps & {
    title: string;
    link?: string;
    isApprovable: boolean;
    isExpirable: boolean;
    tag: null | TaskTagType;
};

export const TaskNameCore: React.FC<TaskNameCoreProps> = ({ title, link, isApprovable, tag = null, isExpirable, priority }) => {
    const titleComponent = (
        <Tooltip title={title} placement="bottom-start">
            <span>{title}</span>
        </Tooltip>
    );

    return (
        <Box
            width="100%"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
        >
            <PriorityTask priority={priority}/>
            {tag && 
                <Box position="absolute" style={{left: '12px'}}>
                    <TagBoxColorTooltip tag={tag} />
                </Box>
            }
            <Box
                maxWidth="90%"
                overflow="hidden"
                textOverflow="ellipsis"
                display="inline-block"
                textAlign="left"
            >
                {link
                    ? <StyledLink to={link}>{titleComponent}</StyledLink>
                    : titleComponent}
            </Box>
            {isApprovable && (
                <Box display="inline-block">
                    <ApprovableIcon />
                </Box>
            )}
            {isExpirable && (
                <Box display="inline-block">
                    <ExpireIcon /> 
                </Box>
            )}
        </Box>
    );
};

export const PriorityTask: React.FC<PriorityTaskProps> = ({ priority }) => {
    const isTaskPriorityEnabled = useFeatureState(FeatureFlags.TaskPriority);
    const title = useMemo(()=> {
        switch (priority) {
            case TaskPriority.Low:
                return `${t('Low Priority')}`;
            case TaskPriority.Medium:
                return `${t('Medium Priority')}`;
            case TaskPriority.High:
                return `${t('High Priority')}`;
            default:
                return '';
        }
    }, [priority]);

    if(!isTaskPriorityEnabled || priority === TaskPriority.None) return null;

    return (
        <PriorityFilterButton
            title={title}
            typePriority={priority}
            onFilterTasksPriority={() => {}}
        />
    )
}
