import { makeStyles, Theme } from "@material-ui/core";
import { BlueColor, Breakpoints, UtilColors } from "utils/styles/constants";

export const useTaskFilterStyles = makeStyles((theme: Theme) => ({
  statusFilter: {
    width: '100%',
    maxWidth: '205px',
    display: 'flex',
    justifyContent: 'center'
  },
  select: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    lineHeight: '24px',
  },
  containerLeft:{
    marginLeft: 'auto',
    width: 82,
    alignItems: 'center',
    flexDirection: 'row',
    columnGap: '2px!important',
    position: 'absolute',
    top: 0,
    right: -80
  },
  buttonFilter: {
    width: 35,
    height: 35,
    marginLeft: 'auto',
    "&.active": {
      color: BlueColor.Blue0,
      textDecoration: 'none',
      backgroundColor: UtilColors.HoverColor,
    },
    '&:hover': {
      backgroundColor: UtilColors.HoverColor,
    }
  },
  autocompleteCreatorFilter: {
    width: '100%',
    maxWidth: 240,
    minWidth: 240,
    [theme.breakpoints.down(Breakpoints.Tablet)]: {
      maxWidth: '100%',
      minWidth: '100%',
    }
  },
  autocompleteCreatorFilterTest: {
    marginLeft: 4,
    marginRight: 4,
  },
  locationContainer: {
    position: 'relative',
    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      gap: '20px 8px!important'
    }
  },
  taskContainer: {
    position: 'relative',
    marginRight: 82
  },
  calendarScheduledTasksFilterIcon:{
    position: 'absolute',
    width: 14,
    height: 14,
    right: -10,
    top: -3
  },
  locationDownloadTable: {
      padding: 5,
      minWidth: 45,
      '&:hover': {
        color: BlueColor.Blue50
      }
  },
  locationDownloadTableContainer:{
    marginLeft: 'auto',
  },
  priorityButton: {
    position: 'relative'
  },
  priorityNone:{
    position: 'absolute',
  },
  prioritySpanIcon: {
    color: 'red',
    fontWeight: 400,
    width: 24,
    height: 24
  }
}));
