import { formatDateCustom } from 'utils/helpers/dateHelper';
import * as yup from 'yup';
import {
    attachmentSchema,
    descriptionSchema,
    dateSchema,
    titleSchema,
    recurrenceSchema,
    TASK_DATE_FORMAT_YYYYMMDD,
    taskPrioritySchema
} from '../common/form/validation';
import { editableChecklistItemSchema } from '../editTask/validation';
import i18n from 'i18n';

export const scheduledTaskValidationSchema = yup.object({
    title: titleSchema,
    description: descriptionSchema,
    attachments: yup.array().of(attachmentSchema),
    publishDate: dateSchema
        .label('Publish Date')
        .min(formatDateCustom(new Date(),TASK_DATE_FORMAT_YYYYMMDD), `${i18n.t('Publish Date could not be earlier than today')}`),
    recurrence: recurrenceSchema,
    dueDate: dateSchema
        .label('Due Date')
        .min(yup.ref('publishDate'), `${i18n.t('Due Date field must be not earlier than Publish Date')}`),
    checklistItems: yup.array()
        .of(editableChecklistItemSchema)
        .required(`${i18n.t('Create at least one checklist item')}`)
        .label('Checklist'),
    sendReminder: yup.boolean().notRequired(),
    priority: taskPrioritySchema
});
