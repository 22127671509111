import { TaskPriority } from "api/task/taskModel";
import { IconButton, Tooltip } from '@material-ui/core';
import { useTaskFilterStyles } from '../../../taskFilterStyles';
import BlockIcon from '@material-ui/icons/Block';
import clsx from "clsx";

type ToggleTasksEventCProps = {
    classes: string;
    classesNone?: string;
}

const NoneTasksPriority: React.FC<ToggleTasksEventCProps> = ({ classes, classesNone }) => <><span className={clsx(classes,classesNone)}>!</span><BlockIcon color="primary" fontSize='medium'></BlockIcon></>;
const LowTasksPriority: React.FC<ToggleTasksEventCProps> = ({ classes }) => <span className={classes}>!</span>;
const MediumTasksPriority: React.FC<ToggleTasksEventCProps> = ({ classes }) => <span className={classes}>!!</span>;
const HighTasksPriority: React.FC<ToggleTasksEventCProps> = ({ classes }) => <span className={classes}>!!!</span>;

type ToggleTasksPriorityComponent = React.FC<ToggleTasksEventCProps>;
export const ToggleTasksPriorityComponents: Record<TaskPriority, ToggleTasksPriorityComponent> = {
    [TaskPriority.None]: NoneTasksPriority,
    [TaskPriority.Low]: LowTasksPriority,
    [TaskPriority.Medium]: MediumTasksPriority,
    [TaskPriority.High]: HighTasksPriority,
};


type PriorityFilterButtonProps = { 
    title: string,
    onFilterTasksPriority: () => void,
    typePriority?: TaskPriority
};

export const PriorityFilterButton: React.FC<PriorityFilterButtonProps> = ({ title, onFilterTasksPriority, typePriority = TaskPriority.None }) => {
    const classes = useTaskFilterStyles();
    const ToogleTypeShow = ToggleTasksPriorityComponents[typePriority];

    return (
        <Tooltip title={title}>
            <IconButton size='small' onClick={onFilterTasksPriority} className={classes.priorityButton}>
                <ToogleTypeShow classes={classes.prioritySpanIcon} classesNone={classes.priorityNone}/>
            </IconButton>
        </Tooltip>
    )
}
