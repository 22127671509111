import { combineReducers } from 'redux';
import { CALENDAR_CALENDAR_FILTER_LOCATION_IDS, CALENDAR_FILTER_CREATOR, CALENDAR_FILTER_RESET, CALENDAR_FILTER_SCHEDULED_TASKS, CALENDAR_FILTER_STATUS, CALENDAR_FILTER_TAGS, CALENDAR_FILTER_TASKS_EVENTS, CALENDAR_FILTER_TASKS_PRIORITY, CalendarFilterActions } from './calendarFilterTaskActions';
import { AutomationTaskStatusFilter, TasksEvents } from 'api/task/automationTask/automationTaskModel';
import { TaskPriority } from 'api/task/taskModel';

const initialState = {
  tags: [],
  locationIds: [],
  status: null,
  createdBy: [],
  scheduledTasks: false,
  tasksEvents: TasksEvents.All,
  tasksPriority: TaskPriority.None
};

export function locationIds(
  state: string[] = initialState.locationIds, action: CalendarFilterActions,
): string[] {
  switch (action.type) {
    case CALENDAR_FILTER_RESET:
      return [];
    case CALENDAR_CALENDAR_FILTER_LOCATION_IDS:
      return action.payload;
    default:
      return state;
  }
}

export function tags(
  state: string[] = initialState.tags, action: CalendarFilterActions,
): string[] {
  switch (action.type) {
    case CALENDAR_FILTER_RESET:
      return [];
    case CALENDAR_FILTER_TAGS:
      return action.payload;
    default:
      return state;
  }
}

export function status(
  state: AutomationTaskStatusFilter | null = initialState.status, action: CalendarFilterActions,
): AutomationTaskStatusFilter | null {
  switch (action.type) {
    case CALENDAR_FILTER_RESET:
      return null;
    case CALENDAR_FILTER_STATUS:
      return action.payload;
    default:
      return state;
  }
}

export function createdBy(
  state: string[] = initialState.createdBy, action: CalendarFilterActions,
): string[] {
  switch (action.type) {
    case CALENDAR_FILTER_RESET:
      return [];
    case CALENDAR_FILTER_CREATOR:
      return action.payload;
    default:
      return state;
  }
}

export function scheduledTasks(
  state: boolean = initialState.scheduledTasks, action: CalendarFilterActions,
): boolean {
  switch (action.type) {
    case CALENDAR_FILTER_RESET:
      return false;
    case CALENDAR_FILTER_SCHEDULED_TASKS:
      return action.payload;
    default:
      return state;
  }
}

export function tasksEvents(
  state: TasksEvents = initialState.tasksEvents, action: CalendarFilterActions,
): TasksEvents {
  if (action.type === CALENDAR_FILTER_TASKS_EVENTS) {
      switch (state) {
        case TasksEvents.All:
          return TasksEvents.Tasks;
        case TasksEvents.Tasks:
          return TasksEvents.Events;
        case TasksEvents.Events:
          return TasksEvents.All;
      }
  }
  return state;
}

export function tasksPriority(
  state: TaskPriority = initialState.tasksPriority, action: CalendarFilterActions,
): TaskPriority {
  if (action.type === CALENDAR_FILTER_TASKS_PRIORITY) {
      switch (state) {
        case TaskPriority.None:
          return TaskPriority.Low;
        case TaskPriority.Low:
          return TaskPriority.Medium;
        case TaskPriority.Medium:
          return TaskPriority.High;
        case TaskPriority.High:
          return TaskPriority.None;
      }
  }
  return state;
}

export const calendarFilterReducer = combineReducers({
  locationIds,
  tags,
  status,
  createdBy,
  scheduledTasks,
  tasksEvents,
  tasksPriority
});
