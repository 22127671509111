import * as t from 'io-ts';

import { ChildStoreTask, childStoreTaskSchema } from 'api/task/parentTaskDetails/parentTaskDetailsModel';
import { sort } from 'fp-ts/Array';
import { Ord } from 'fp-ts/Ord';
import { Ordering } from 'fp-ts/Ordering';
import { isRight } from 'fp-ts/Either';
import { addPriorityTask, storeTaskStatus, taskShortSchemaBase } from '../common/taskCommonModel';
import { ListSearchRequestBase } from "../../common/model";
import { nullableStringType } from 'model/dataBasicTypes';
import { IEntity } from 'model/entity';
import { FeatureFlagState } from 'store/modules/featureFlags/model';
import { taskTagSchema } from '../tag/taskTagModel';
import { fromEnum } from 'utils/helpers/fromEnum';
import { TaskStatus } from '../taskModel';
import { EventCalendarActivity } from '../calendarTask/calendaTaskModel';

export const storeTaskAttachmentsSchema = t.type({
    fileId: t.string,
    name: t.string,
    hasThumbnail: t.boolean,
});

export const storeTaskChecklistSchema = t.type({
    id: t.string,
    order: t.number,
    name: t.string,
    completed: t.boolean,
    requiresAttachment: t.boolean,
    attachments: t.array(storeTaskAttachmentsSchema),
    requiresCustomResponse: t.union([t.undefined, t.null, t.boolean]),
    customResponse: t.union([t.undefined, t.null, t.string]),
    completedAt: t.union([t.undefined, t.null, t.string]),
    completedBy: t.union([t.undefined, t.null, t.string]),
}); 

export type StoreTaskChecklistModel = t.TypeOf<typeof storeTaskChecklistSchema>;

class ChecklistItemsOrder implements Ord<StoreTaskChecklistModel> {
    compare(a: StoreTaskChecklistModel, b: StoreTaskChecklistModel): Ordering {
        // order of items cannot be equal
        return a.order > b.order ? 1 : -1;
    }
    equals(a: StoreTaskChecklistModel, b: StoreTaskChecklistModel): boolean {
        return a.order === b.order;
    }
}

const storeTaskSortedChecklistSchema = new t.Type<
    Array<StoreTaskChecklistModel>, Array<StoreTaskChecklistModel>, Array<StoreTaskChecklistModel>
>(
    'storeTaskSortedChecklist',
    (s: unknown): s is Array<StoreTaskChecklistModel> => isRight(t.array(storeTaskChecklistSchema).decode(s)),
    param => t.success(
        sort<StoreTaskChecklistModel>(new ChecklistItemsOrder())(param),
    ),
    value => value,

);

export const storeTaskShortSchema = t.intersection([
    taskShortSchemaBase,
    t.type({
        status: t.string,
        createdAt: t.string,
        isOverdue: t.boolean,
        isApproval: t.boolean,
        isExpired: t.boolean,
        isExpirable: t.boolean,
        tag: t.union([t.null, taskTagSchema])
    }),
    storeTaskStatus,
    addPriorityTask,
]);
export type StoreTaskShort = t.TypeOf<typeof storeTaskShortSchema>;

export enum StoreTaskStatusFilter {
    Incomplete = 'incomplete',
    Expired = 'expired',
    Completed = 'completed',
    Overdue = 'overdue',
    Submitted = 'submitted',
    SubmittedOverdue = 'submittedOverdue',
    CompletedOverdue = 'completedOverdue',
}

export enum StoreTasksSortBy {
    Title = 'title',
    DueDate = 'dueDate',
    Status = 'status',
    CreatedBy = 'createdBy',
}

export type StoreTaskSearchRequest = ListSearchRequestBase<StoreTasksSortBy> & {
    locationId: string;
    filter?: StoreTaskStatusFilter;
    tasksViewMode?: string;
};

export const storeTaskSearchResponseSchema = t.type({
    nextPageToken: nullableStringType,
    tasks: t.array(storeTaskShortSchema),
});
export type StoreTaskSearchResponse = t.TypeOf<typeof storeTaskSearchResponseSchema>;

export const commonTaskActivityEventSchema = t.type({
    eventType: t.string,
    taskId: t.string,
    userId: t.string,
    createdOn: t.string,
    isOverdue: t.union([t.boolean, t.undefined]),
    clientId: t.union([t.null, t.string]),
})

export const taskDeclinedActivityEventSchema = t.intersection([
    commonTaskActivityEventSchema,
    t.type({
        reason: t.union([t.string, t.null]),
    })
]);

export const fieldChangeSchema = t.type({
    fieldName: t.string,
    oldValue: t.unknown,
    newValue: t.unknown,
});

export const taskEditedActivityEventSchema = t.intersection([
    commonTaskActivityEventSchema,
    t.type({
        fieldChanges: t.union([t.null, t.array(fieldChangeSchema)]),
    })
]);

export const taskActivityEventSchema = t.union([
    commonTaskActivityEventSchema,
    taskDeclinedActivityEventSchema,
    taskEditedActivityEventSchema,
]);

export type TaskFieldChange = t.TypeOf<typeof fieldChangeSchema>;
export type CommonTaskActivityEvent = t.TypeOf<typeof commonTaskActivityEventSchema>;
export type TaskDeclinedActivityEvent = t.TypeOf<typeof taskDeclinedActivityEventSchema>;
export type TaskEditedActivityEvent = t.TypeOf<typeof taskEditedActivityEventSchema>;
export type StoreTaskActivityEvent = CommonTaskActivityEvent | TaskDeclinedActivityEvent | TaskEditedActivityEvent | EventCalendarActivity;

export const storeTaskSchema = t.intersection([
    childStoreTaskSchema,
    addPriorityTask,
    t.type({
        title: t.string,
        description: t.string,
        version: t.union([t.number, t.undefined]),
        prototypeId: t.string,
        parentTaskId: t.union([t.string, t.null]),
        isApproval: t.union([t.undefined, t.boolean]),
        isExpired:t.union([t.undefined, t.boolean]),
        dueDate: t.string,
        createdBy: t.string,
        createdAt: t.string,
        attachments: t.array(storeTaskAttachmentsSchema),
        prototypeAttachments: t.array(storeTaskAttachmentsSchema),
        checklistItems: t.array(storeTaskChecklistSchema).pipe(storeTaskSortedChecklistSchema),
        events: t.union([t.undefined, t.array(taskActivityEventSchema)]),
        watchers: t.union([t.undefined, t.null, t.array(t.string)]),
        tag: t.union([t.null, taskTagSchema]),
    }),
]);

export const taskAutomationdActivityEventSchema =  t.type({
    eventType: t.string,
    createdOn: t.string,
    fieldChanges: t.union([t.null, t.array(fieldChangeSchema)]),
});

export const automationTaskSchema = t.intersection([
    storeTaskStatus,
    t.type({
        id: t.string,
        status: fromEnum('TaskStatus', TaskStatus),
        dueDate: t.string,
        createdAt: t.string,
        isOverdue: t.boolean,
        title: t.string,
        description: t.string,
        events: t.union([t.undefined, t.array(taskAutomationdActivityEventSchema)]),
        checklist: t.array(storeTaskChecklistSchema).pipe(storeTaskSortedChecklistSchema),
    })
]);

export type StoreTaskFullModel = t.TypeOf<typeof storeTaskSchema>;
export type StoreTaskFullOrChildModel = StoreTaskFullModel | ChildStoreTask;
export type StoreTaskAttachmentModel = t.TypeOf<typeof storeTaskAttachmentsSchema>;
export type AutomationTaskFullModel = t.TypeOf<typeof automationTaskSchema>;

export interface IChecklistItemFileOperation {
    taskId: string;
    checklistItemId: string;
}

export interface IChecklistItemChangeStatusSuccess extends Pick<IChecklistItemFileOperation, 'checklistItemId'> {
    task: StoreTaskFullModel;
    checklistItemId: string;
}

export interface IChecklistItemChangeStatus extends IChecklistItemFileOperation {
    completed: boolean;
}

export interface IChecklistItemCustomResponse extends IChecklistItemFileOperation{
    customResponse: string;
    itemName: string;
}

export interface IChecklistItemCustomSuccess extends IChecklistItemFileOperation{
    task: StoreTaskFullModel;
}

export interface IChecklistItemUploadFiles extends IChecklistItemFileOperation {
    taskId: string;
    files: Array<File>;
    chunkUploadFeatureState?: FeatureFlagState;
}

export interface IChecklistItemUploadFilesSuccess extends IChecklistItemFileOperation {
    task: StoreTaskFullModel;
    fileNames: Array<string>;
}

export interface IChecklistItemUploadFilesError extends IChecklistItemFileOperation {
    fileNames: Array<string>;
}

export interface IStoreTaskApprovableResult extends IEntity {
    userCanApprove: boolean;
}

export interface IStoreTaskDeclineRequest {
    taskId: string;
    reason: string;
}

export interface IStoreTaskParentIdsResult extends IEntity {
    parentIds: string[];
}
