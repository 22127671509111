import * as yup from 'yup';
import { UploadedFile } from 'store/modules/files/filesModel';
import { locationListGetStores } from 'store/modules/hierarchy/hierarchyHelpers';
import { IChecklistItem, TaskCreateRequest, TaskRecurrence, LabelCreateRequest, TaskEditRequest, TaskPriority } from 'api/task/taskModel';
import { HierarchyState } from 'store/modules/hierarchy/hierarchyReducer';
import {
    attachmentSchema,
    checklistItemSchema,
    descriptionSchema,
    dateSchema,
    titleSchema,
    recurrenceSchema,
    tagSchema,
    taskPrioritySchema
} from '../common/form/validation';
import { TaskPrototypeUpdate } from 'api/task/prototype/prototypeModel';
import { TaskTagNewType } from 'api/task/tag/taskTagModel';
import { Stores } from 'api/store/storeModel';
import { TaskTemplateDetailsType } from 'api/task/taskTemplate/taskTemplateModel';
import i18n from 'i18n';

export interface ICreateTask {
    title: string;
    publishDate: string;
    recurrence?: TaskRecurrence;
    dueDate: string;
    description: string;
    subtasks: Array<IChecklistItem>;
    locations: Array<string>;
    attachments?: UploadedFile[];
    watchers?: Array<string>;
    isExpirable?: boolean;
    tag: TaskTagNewType | null;
    createdBy: string;
    isNewTemplate?: boolean;
    locationsFromTemplate?: Stores;
    sendReminder?: boolean;
    priority?: TaskPriority;
}

export interface ICreateLabel {
    name: string;
    color: string;
}

export const createTaskToPayload = (task: ICreateTask, isTaskTemplate = false, idTemplate = ''): TaskCreateRequest => {

    return {
        title: task.title,
        description: task.description,
        publishDate: task.publishDate,
        recurrence: task.recurrence || null,
        dueDate: task.dueDate,
        selectedCompanyLocations: !isTaskTemplate ? task.locations : undefined,
        checklist: task.subtasks.map(({ name, requiresAttachment, requiresCustomResponse}, index) => ({
            id: undefined,
            name,
            requiresAttachment,
            requiresCustomResponse,
            order: index,
        })),
        attachments: task.attachments || [],
        watchers: task.watchers || [],
        isExpirable: task.isExpirable || false,
        tag: task.tag,
        createdBy: task.createdBy,
        locationIds: isTaskTemplate ? task.locations : undefined,
        id: isTaskTemplate ? idTemplate : undefined,
        sendReminder: task.sendReminder,
        priority: task.priority
    }
}

export const addTaskSchema = {
    title: titleSchema,
    description: descriptionSchema,
    attachments: yup.array().of(attachmentSchema),
    publishDate: dateSchema
        .label('Publish Date'),
    recurrence: recurrenceSchema,
    dueDate: dateSchema
        .label('Due Date'),
    subtasks: yup.array()
        .of(checklistItemSchema)
        .required(`${i18n.t('Create at least one checklist item')}`)
        .label('Checklist'),
    isExpirable: yup.boolean().notRequired(),
    tag: tagSchema,
    createdBy: yup.string().notRequired(),
    isNewTemplate: yup.boolean().notRequired(),
    sendReminder: yup.boolean().notRequired(),
    priority: taskPrioritySchema
};

export const createLocationSchema = (hierarchy: HierarchyState) => ({
    locations: yup.array().of(yup.string().required())
        .required(`${i18n.t('Selected 0 stores. Please assign the task to one store at least')}`)
        .label('Locations')
        .test(
            'storesAmount',
            `${i18n.t('No Stores in the selected Region/District. Please assign task to at least one Store.')}`,
            value => locationListGetStores(hierarchy, value ?? []).length > 0,
        ),
});

export const createLabelToPayload = (label: ICreateLabel): LabelCreateRequest => {

    return {
        name: label.name,
        color: label.color
    }
}

export const createTaskCloneToPayload = (task: TaskPrototypeUpdate): TaskCreateRequest => {
    return {
        title: task.title,
        description: task.description || '',
        publishDate: task.publishDate,
        recurrence: task.recurrence,
        dueDate: task.dueDate,
        selectedCompanyLocations: task.locationIds,
        checklist: task.checklistItems.map(({ name, requiresAttachment,requiresCustomResponse}, index) => ({
            id: undefined,
            name,
            requiresAttachment,
            requiresCustomResponse,
            order: index,
        })),
        attachments: task.attachments || [],
        watchers: task.watchers || [],
        isExpirable: task.isExpirable || false,
        tag: task.tag,
        createdBy: task.createdBy,
        sendReminder: task.sendReminder,
        priority: task.priority
    } as TaskCreateRequest
}

export const editTaskToPayload = (task: TaskPrototypeUpdate): TaskEditRequest => {
    return {
        id: task.id,
        title: task.title,
        description: task.description || '',
        dueDate: task.dueDate,
        locationIds: task.locationIds,
        checklistItems: task.checklistItems,
        attachments: task.attachments || [],
        watchers: task.watchers || [],
        isExpirable: task.isExpirable,
        tag: task.tag,
        createdBy: task.createdBy,
        sendReminder: task.sendReminder,
        priority: task.priority
    } as TaskEditRequest
}

export const taskTemplateToCreatTask = (task: TaskTemplateDetailsType): ICreateTask => {
    return {
        title: task.title,
        description: task.description || '',
        publishDate: task.publishDate,
        recurrence: task.recurrence,
        label: '',
        dueDate: task.dueDate,
        locations: task.locations.map((loc)=>loc.id),
        locationsFromTemplate: task.locations,
        subtasks: task.checklistItems
            .sort((a, b) => a.order - b.order)
            .map(({ name, requiresAttachment,requiresCustomResponse, order}) => ({
                id: undefined,
                name,
                requiresAttachment,
                requiresCustomResponse,
                order,
            })),
        attachments: task?.attachments || [],
        watchers: task.watchers || [],
        isExpirable: task.isExpirable || false,
        tag: task.tag,
        createdBy: task.createdBy,
        sendReminder: task.sendReminder,
        priority: task.priority
    } as ICreateTask
}

export const createEditTaskToTemplate = (task: TaskPrototypeUpdate): TaskCreateRequest => {

    return {
        title: task.title,
        description: task.description || '',
        publishDate: task.publishDate || '',
        recurrence: task?.recurrence ? task?.recurrence as TaskRecurrence : null,
        dueDate: task.dueDate,
        checklist: task.checklistItems.map(({ name, requiresAttachment, requiresCustomResponse}, index) => ({
            id: undefined,
            name,
            requiresAttachment,
            requiresCustomResponse,
            order: index,
        })),
        attachments: task.attachments || [],
        watchers: task.watchers || [],
        isExpirable: task.isExpirable || false,
        tag: task.tag || null,
        createdBy: task.createdBy || '',
        locationIds: task.locationIds || [],
        sendReminder: task.sendReminder,
        selectedCompanyLocations: undefined,
        id: undefined,
        priority: task.priority
    }
}
