import React, { useCallback, useMemo } from 'react';
import { Box, Switch, Typography } from '@material-ui/core';
import { useFormStyles } from 'modules/shared/components/formStyles';
import { FormikHandlers, useField } from 'formik';
import { TextInput } from 'components/basicInputs/textInput/TextInput';
import { MarkdownInput } from 'components/basicInputs/MarkdownInput';
import { TASK_DATE_FORMAT, TASK_MAX_TITLE_LENGTH } from 'modules/task/common/form/validation';
import { DateInput } from 'components/basicInputs/dateInput/DateInput';
import { AttachmentsEdit } from '../attachments/Attachments/AttachmentsEdit';
import { TaskLocationsView } from '../locations/TaskLocationsView';
import { TaskChecklist } from '../common/checklist/TaskChecklist';
import { RecurrenceDropdown } from '../common/recurrence/RecurrenceDropdown';
import { FormActions } from 'modules/shared/components/FormActions';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { TaskTaglist } from '../common/taglist/TaskTaglist';
import { WatchersDropdown } from '../common/watchers/WatchersDropdown';
import { Switch as SwitchCustom } from 'components/switch/Switch';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { t } from 'i18next';
import { TitleForm } from '../common/titleForm';
import { Trans } from 'react-i18next';
import { IChecklistItem } from 'api/task/taskModel';
import { TaskPriority } from '../common/TaskPriority';

export type EditTaskTemplateFormProps = {
    isDisabled?: boolean;
    isUpdating: boolean;
    handleSubmit: FormikHandlers['handleSubmit']
};

const isExpiredDateFieldName: string = 'isExpirable';
const createdByFieldName: string = 'createdBy';
const checkistFieldName: string = 'checklistItems';

export function EditTaskTemplateForm({ isDisabled, isUpdating, handleSubmit }: EditTaskTemplateFormProps) {
    const classes = useFormStyles([]);
    const isTagEnabled = useFeatureState(FeatureFlags.TaskTags);
    const isWatchersEnabled = useFeatureState(FeatureFlags.TaskWatchers);
    const istasksScheduledTaskRemoveStoreEnabled = useFeatureState(FeatureFlags.TasksScheduledTaskRemoveStore);
    const isNtfTaskDueReminderEnabled = useFeatureState(FeatureFlags.NtfTaskDueReminder);
    const isExpandableTasksEnabled = useFeatureState(FeatureFlags.TaksExpirable);
    const [fieldcreatedBy] = useField(createdByFieldName);
    const [fieldChecklistItems] = useField(checkistFieldName);
    const [fieldIsExpiredDate] = useField(isExpiredDateFieldName);
    const { value: valueIsExpirable, onChange } = fieldIsExpiredDate;
    const { value: valueCreatedBy } = fieldcreatedBy;
    const isApproval = useMemo(() => (
        fieldChecklistItems.value.some((item: IChecklistItem) => item.requiresAttachment || item.requiresCustomResponse)
    ), [fieldChecklistItems]);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { checked, name } } = event;
        onChange({
            target: {
                name: name,
                value: checked,
            }
        })
    }, [onChange]);

    return (
        <form onSubmit={handleSubmit}>
            <TitleForm
                title={<Trans>Edit Task</Trans>}
                tagList={isTagEnabled && <TaskTaglist nameField="tag"/>}
                showHourGlass={valueIsExpirable}
                isApproval={isApproval}
            />
            <Box className={classes.formInputs}>
                <TextInput
                    disabled={isDisabled}
                    classes={{ root: classes.titleInput }}
                    name="title"
                    label={`${t('Task Name')}`}
                    showCounter
                    maxLength={TASK_MAX_TITLE_LENGTH}
                    startAdornment={<TaskPriority />}
                />

                <Box className={classes.dateWrapper}>
                    <DateInput
                        disabled={isDisabled}
                        name="publishDate"
                        label={`${t('Publish Date')}`}
                        valueFormat={TASK_DATE_FORMAT}
                        showTooltipLabel
                        tooltipTitle={`${t('Scheduled tasks will schedule based on the store(s) local time zone')}`}
                    />
                </Box>

                <Box className={classes.recurrenceWrapper}>
                    <RecurrenceDropdown
                        disabled={isDisabled}
                        name="recurrence"
                        label={`${t('Recurrence')}`}
                        refDateName="publishDate"
                        showTooltipLabel
                        tooltipTitle={`${t('Scheduled tasks will schedule based on the store(s) local time zone')}`}
                    />
                </Box>

                <Box className={classes.dateWrapper}>
                    <DateInput
                        disabled={isDisabled}
                        name="dueDate"
                        label={`${t('Due Date')}`}
                        valueFormat={TASK_DATE_FORMAT}
                        showTooltipLabel
                        tooltipTitle={`${t('Scheduled tasks will schedule based on the store(s) local time zone')}`}
                    />
                    { isExpandableTasksEnabled && 
                        <>
                            <Switch
                                checked={valueIsExpirable}
                                edge="start"
                                name="isExpirable"
                                color="primary"
                                onChange={handleChange}
                            />
                            <Typography variant="caption">
                                <Trans>Expire at Due Date</Trans>
                            </Typography>
                        </>
                    }
                </Box>

                <Box className={classes.recurrenceWrapper}>
                {isWatchersEnabled && (
                        <WatchersDropdown
                            disabled={isDisabled}
                            name="watchers"
                            label={`${t('Watchers')}`}
                            cretedBy={valueCreatedBy}
                        />

                )}
                {isNtfTaskDueReminderEnabled && (
                    <SwitchCustom
                        disabled={isDisabled}
                        name="sendReminder"
                        label={t('Send Reminders')}
                        titleTooltip={<Trans>Sends Task Reminder Notification for Incomplete Tasks 12 & 6 Hours Before Due</Trans>}
                        bodyTooltip={<ContactSupportIcon color="inherit" fontSize="small" />}
                        showTooltip
                    />)}
                    </Box>
                <MarkdownInput
                    disabled={isDisabled}
                    name="description"
                    label={`${t('Description (optional)')}`}
                    classes={{ root: classes.descriptionInput }}
                />

                <AttachmentsEdit disabled={isDisabled} />

                <TaskLocationsView fieldName='locationIds' canDeleteStore={istasksScheduledTaskRemoveStoreEnabled && !isDisabled}/>

                <TaskChecklist
                    disabled={isDisabled}
                    fieldName='checklistItems'
                />
            </Box>

            <FormActions
                disabled={isDisabled}
                isInProgress={isUpdating}
            />
        </form>
    );
}
