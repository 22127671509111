import { CalendarPriorityFilter } from '../calendar/CalendarPriorityFilter';
import { TasksPriorityFilter } from '../tasksList/TasksPriorityFilter';

export enum TypePriorityFilter {
    Calendar = 'calendar',
    TaksList = 'task-list'
}

type PriorityFilterComponentProps = {
    type: TypePriorityFilter
}

export const PriorityFilterComponent: React.FC<PriorityFilterComponentProps> = ({ type }) => {

    const TasksPriorityFilterTypeShow = TasksPriorityFilterComponents[type];

    return <TasksPriorityFilterTypeShow />
}

const TasksPriorityFilterComponents: Record<TypePriorityFilter, React.FC> = {
    [TypePriorityFilter.Calendar]: CalendarPriorityFilter,
    [TypePriorityFilter.TaksList]: TasksPriorityFilter,
};
