import * as yup from 'yup';
import {
    attachmentSchema,
    checklistItemNameSchema,
    descriptionSchema,
    dateSchema,
    titleSchema,
    recurrenceSchema,
    tagSchema,
    taskPrioritySchema,
} from '../common/form/validation';
import i18n from 'i18n';
import { HierarchyState } from 'store/modules/hierarchy/hierarchyReducer';
import { locationListGetStores } from 'store/modules/hierarchy/hierarchyHelpers';

export const editableChecklistItemSchema = yup.object().shape({
    id: yup.string().optional(),
    name: checklistItemNameSchema,
    requiresAttachment: yup.boolean().required(),
    requiresCustomResponse: yup.boolean().notRequired(),
});

export const editTaskValidationSchema = {
    title: titleSchema,
    description: descriptionSchema,
    attachments: yup.array().of(attachmentSchema),
    dueDate: dateSchema
        .label('Due Date'),
    checklistItems: yup.array()
        .of(editableChecklistItemSchema)
        .required(`${i18n.t('Create at least one checklist item')}`)
        .label('Checklist'),
    isExpirable: yup.boolean().notRequired(),
    tag: tagSchema,
    sendReminder: yup.boolean().notRequired(),
    isNewTemplate: yup.boolean().notRequired(),
    priority: taskPrioritySchema
};

export const editCloneTaskValidationSchema = {
    title: titleSchema,
    description: descriptionSchema,
    attachments: yup.array().of(attachmentSchema),
    checklistItems: yup.array()
        .of(editableChecklistItemSchema)
        .required(`${i18n.t('Create at least one checklist item')}`)
        .label('Checklist'),
    isExpirable: yup.boolean().notRequired(),
    recurrence: recurrenceSchema,
    publishDate: dateSchema
        .min(new Date(), `${i18n.t('Publish Date could not be earlier than today')}`)
        .label('Publish Date'),
    dueDate: dateSchema
        .min(yup.ref('publishDate'), `${i18n.t('Due Date field must be not earlier than Publish Date')}`)
        .label('Due Date'),
    tag: tagSchema,
    sendReminder: yup.boolean().notRequired(),
};

export const createLocationCloneSchema = (hierarchy: HierarchyState) => ({
    locationIds: yup.array().of(yup.string().required())
        .required(`${i18n.t('Selected 0 stores. Please assign the task to one store at least')}`)
        .label('Locations')
        .test(
            'storesAmount',
            `${i18n.t('No Stores in the selected Region/District. Please assign task to at least one Store.')}`,
            value => locationListGetStores(hierarchy, value ?? []).length > 0,
        ),
});
