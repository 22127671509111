/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { CellProps } from 'react-table';
import { IParentTaskGridRowData } from '../model';
import { Box } from '@material-ui/core';
import { CompletionStatusCell } from 'modules/task/taskList/views/ParentTasksView/grid/cells/CompletionStatusCell';
import { TaskNameCore } from 'modules/task/taskList/components/sharedCells/TaskName';

export const TaskNameCell: React.FC<CellProps<IParentTaskGridRowData, string>> = props => {
    if (props.row.original.isSkeleton) {
        return <Skeleton width="200px" />;
    }

    const { id: taskId, approvable = false, expire = false, tag, priority } = props.row.original;

    return (
        <TaskNameCore
            title={props.value}
            link={`/tasks/list/${taskId}`}
            isApprovable={approvable}
            isExpirable={expire}
            tag={tag}
            priority={priority}
        />
    );
};

export function TaskNameMobileCell({ row, value, ...props }: CellProps<IParentTaskGridRowData>) {
    const { isSkeleton, id: taskId, approvable = false, expire = false, tag, priority } = row.original;

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
        >
            {isSkeleton ? (
                <>
                    <Skeleton width="200px" />
                    <Skeleton width="160px" />
                </>
            ) : (
                <>
                    <TaskNameCore
                        title={value}
                        link={`/tasks/list/${taskId}`}
                        isApprovable={approvable}
                        isExpirable={expire}
                        tag={tag}
                        priority={priority}
                    />
                    <CompletionStatusCell
                        value={undefined}
                        row={row}
                        {...props}
                    />
                </>
            )}
        </Box>
    );
}
