import React, { useCallback, useMemo } from 'react';
import { Skeleton } from '@material-ui/lab';
import { useTaskByLocationsHeaderStyles } from 'modules/task/taskList/views/TaskByLocationsView/hooks/useTaskByLocationsHeaderStyles';
import { StoreTaskFullModel, StoreTaskShort } from 'api/task/storeTask/storeTaskModel';
import { TaskHeaderDetails } from 'modules/task/taskList/components/TaskHeaderDetails/TaskHeaderDetails';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { StyledDotWrapper } from 'modules/task/taskList/components/SimpleTaskListHeader';
import { CircularProgressWithLabel } from 'components/userFeedback/CircularProgressWithLabel';
import { TaskIcon } from 'modules/task/singleTask/components/TaskIcon';
import { Breakpoints } from 'utils/styles/constants';
import { ApprovableHeaderIcon } from 'modules/task/taskList/components/ApprovableIcon';
import { ParentTaskChildModel, ParentTaskDetailsModel } from 'api/task/parentTaskDetails/parentTaskDetailsModel';
import { calculateTaskProgress } from 'modules/task/utils/progress';
import { TaskDirectLink } from 'modules/task/common/TaskDirectLink';
import { ExpireHeaderIcon } from 'modules/task/taskList/components/ExpireIcon';
import { TagBoxColorTooltip } from 'modules/task/common/taglist/TagBoxColorTooltip';
import { TaskStatus } from 'api/task/taskModel';
import { PriorityTask } from 'modules/task/taskList/components/sharedCells/TaskName';

export type TaskHeaderProps = {
    task?: StoreTaskFullModel | StoreTaskShort | ParentTaskDetailsModel | ParentTaskChildModel;
    showProgress?: boolean;
    title?: React.ReactNode;
    details?: React.ReactNode;
    isAutomationTask?: boolean;
}

const useTaskHeaderStyles = makeStyles((theme: Theme) => ({
    locationName: {
        fontSize: '24px',
        lineHeight: '32.02px',
        fontWeight: 400,

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            fontSize: '20px',
            lineHeight: '26.68px',
        },
    },
    directLinkIcon: {
        lineHeight: 0,
        marginLeft: theme.spacing(0.5),
    },
}));

export function TaskHeader({ task, showProgress, title, details, isAutomationTask = false }: TaskHeaderProps) {
    const taskByLocationHeaderClasses = useTaskByLocationsHeaderStyles([]);
    const classes = useTaskHeaderStyles([]);
    const storeTaskFull = task as StoreTaskFullModel;
    const locationName = (task as StoreTaskFullModel)?.locationName || undefined;
    const progress = useMemo(() => {
        return showProgress && storeTaskFull
            ? calculateTaskProgress(storeTaskFull)
            : 0;
    }, [showProgress, storeTaskFull]);

    const renderTitle = useCallback(() => {
        return storeTaskFull.title
            ? (
                <>
                    {storeTaskFull?.priority && <PriorityTask priority={storeTaskFull.priority}/> }
                    <h2 className={taskByLocationHeaderClasses.title}>
                        {storeTaskFull?.title}
                    </h2>
                </>
            )
            : <Skeleton width={160} />
    }, [storeTaskFull, taskByLocationHeaderClasses]);

    return (
        <header>
            <div className={taskByLocationHeaderClasses.infoWrapper}>
                <div className={taskByLocationHeaderClasses.info}>
                    <div className={taskByLocationHeaderClasses.titleWrapper}>
                        {task ? (
                            <>
                                <Box className={taskByLocationHeaderClasses.headerTitleLocationContainer}>
                                    <TaskIcon status={task.displayedStatus} />
                                    {title ?? renderTitle()}
                                </Box>
                                <Box className={taskByLocationHeaderClasses.headerTitleLocationContainer}>
                                    {!isAutomationTask && <StyledDotWrapper>•</StyledDotWrapper>}
                                    <Typography variant="h5" className={classes.locationName}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="flex-start"
                                        >
                                            {locationName || <Skeleton width="100px" />}
                                            {storeTaskFull?.isApproval && <ApprovableHeaderIcon />}
                                            {(!storeTaskFull?.isExpired  || storeTaskFull?.status === TaskStatus.Submitted) && storeTaskFull?.isExpirable  && <ExpireHeaderIcon />}
                                            {task && !isAutomationTask && (
                                                <TaskDirectLink className={classes.directLinkIcon} taskId={task.id} />
                                            )}
                                            {storeTaskFull?.tag && (<Box display="flex" alignItems="center" ml={.5}><TagBoxColorTooltip tag={storeTaskFull?.tag} /></Box>)}
                                        </Box>
                                    </Typography>
                                </Box>
                            </>
                        ) : (
                            <Skeleton height={32} width={250} />
                        )}
                    </div>

                    {details ?? (
                        <TaskHeaderDetails
                            dueDate={storeTaskFull?.dueDate}
                            taskStatus={task?.displayedStatus}
                            createdBy={storeTaskFull?.createdBy}
                        />
                    )}
                </div>
                {showProgress && (
                    <div>
                        <CircularProgressWithLabel variant="determinate" value={progress} />
                    </div>
                )}
            </div>
        </header>
    );
}
