import { createSingleAction } from "utils/store/actionUtils";

export const TASK_FILTER_TAB = 'task/TASK_FILTER_TAB';

export const taskFilterTab = createSingleAction<string, typeof TASK_FILTER_TAB>(
  TASK_FILTER_TAB,
);
export const TASK_CREATED_BY_FILTER_ACTIVE = 'task/filter/TASK_CREATED_BY_FILTER_ACTIVE';
export const addFilterTaskCreatedBy = createSingleAction<string[], typeof TASK_CREATED_BY_FILTER_ACTIVE>(
  TASK_CREATED_BY_FILTER_ACTIVE,
);

export const TASKS_LIST_BY_PRIORITY = 'task/filter/TASKS_LIST_BY_PRIORITY';
export const FilterTasksListPriority = createSingleAction<void, typeof TASKS_LIST_BY_PRIORITY>(
  TASKS_LIST_BY_PRIORITY,
);

export type TaskFilterTab =
    | ReturnType<typeof taskFilterTab>
    | ReturnType<typeof addFilterTaskCreatedBy>
    | ReturnType<typeof FilterTasksListPriority>;
