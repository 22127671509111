import React, { useCallback } from 'react';
import { Box, ThemeProvider } from '@material-ui/core';
import { useFormStyles } from 'modules/shared/components/formStyles';
import { FormikHandlers } from 'formik';
import { generatePath } from "react-router";
import { useSelector } from 'react-redux';
import { TASK_DATE_FORMAT, TASK_DATE_FORMAT_INIT, TASK_MAX_TITLE_LENGTH } from 'modules/task/common/form/validation';
import { FormActions } from 'modules/shared/components/FormActions';
import { TaskLocationsCreate } from 'modules/task/locations/TaskLocationsCreate';
import { AttachmentsCreate } from 'modules/task/attachments/Attachments';
import { TaskTaglist } from '../common/taglist/TaskTaglist';
import { Routes, browserHistory } from 'components/router/model';
import { TextInput } from 'components/basicInputs/textInput/TextInput';
import { DateInput } from 'components/basicInputs/dateInput/DateInput';
import { MarkdownInput } from 'components/basicInputs/MarkdownInput';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { TitleForm } from '../common/titleForm';
import { selectCalendarEventCreating } from 'store/modules/task/calendarTask/calendarSelectors';
import { TaskPriority } from '../common/TaskPriority';

type AddEventFormProps = {
    handleSubmit: FormikHandlers['handleSubmit']
}

export function AddEventForm({ handleSubmit }: AddEventFormProps) {
    const classes = useFormStyles();
    const isTagEnabled = useFeatureState(FeatureFlags.TaskTags);
    const calendarEventCreating = useSelector(selectCalendarEventCreating);

    const handleCancel = useCallback(() => {
        browserHistory.push(generatePath(Routes.TaskListCalendar));
    }, []);

    return (
        <form onSubmit={handleSubmit} data-testid="addEventForm">
            <TitleForm
                title={<Trans>Create Calendar Event</Trans>}
                tagList={isTagEnabled && <TaskTaglist nameField="tag" />}
                isApproval={false}
            />
            <Box className={classes.formInputs}>
                <TextInput
                    classes={{ root: classes.titleInput }}
                    name="title"
                    label={`${t('Calendar Event Name')}`}
                    showCounter
                    maxLength={TASK_MAX_TITLE_LENGTH}
                    startAdornment={<TaskPriority />}
                />

                <Box className={classes.dateWrapper}>
                    <DateInput
                        name="eventStart"
                        label={`${t('Start Date')}`}
                        valueFormat={TASK_DATE_FORMAT_INIT}
                    />
                </Box>

                <Box className={classes.recurrenceWrapper}>
                    <DateInput
                        name="eventEnd"
                        label={`${t('End Date')}`}
                        valueFormat={TASK_DATE_FORMAT}
                        minDate={new Date().toString()}
                    />
                </Box>

                <MarkdownInput
                    name="description"
                    label={`${t('Description (optional)')}`}
                    classes={{ root: classes.descriptionInput }}
                />

                <ThemeProvider theme={{ MuiButton: {} }}>
                    <AttachmentsCreate labelText={`${t('Calendar Event Creator Attachments')}`}/>
                    <TaskLocationsCreate fieldName='locations' fieldNameFromTemplate='locationsFromTemplate' />
                </ThemeProvider>

            </Box>

            <FormActions
                isInProgress={calendarEventCreating}
                btnSaveText={`${t('Create')}`}
                onClickCancel={handleCancel} />
        </form>
    );
}
