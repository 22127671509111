import { FlexBox } from 'modules/shared/components/FlexBox';
import { SimpleTaskListHeader } from 'modules/task/taskList/components/SimpleTaskListHeader';
import { ButtonSwitchType } from 'modules/task/taskList/components/ViewSwitch/TypeViewSwithMap';
import { ViewSwitch } from 'modules/task/taskList/components/ViewSwitch/ViewSwitch';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectLocationsByIds } from 'store/modules/hierarchy/hierarchySelectors';
import { TasksFilterComponentProps } from '../TypeTaskFilterMap';
import { useTaskFilterStyles } from '../taskFilterStyles';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import TasksToReviewButton from 'modules/task/taskReview/TasksToReviewButton';
import { OptionsDropdown } from 'modules/shared/components/OptionsDropdown';
import { allTasksSyntheticValue, getAutomationTaskFilterOptions, getParentTaskFilterOptions } from 'modules/task/taskList/components/model';
import { TasksFilterContext } from 'modules/task/taskList/context/taskFilterContext';
import { TasksViewModeMyTasks } from 'api/task/taskFilter/taskFilterModel';
import { selectFeatureFlags } from 'store/modules/featureFlags/selectors';
import { FeatureFlags } from 'utils/featureFlags';
import { CollapseBox } from 'modules/connect/messenger/components/ChatHeader/chatActions/InviteAction/CollapseBox';
import { TasksTagFilter } from './common/TasksTagFilter';
import { TaskCreatorFilter } from './common/TaskCreatorFilter';
import { FlexBoxContainer } from './common/FlexBoxContainer';
import { FilterButton } from './common/FilterButton';
import { PriorityFilterComponent as PriorityFilter, TypePriorityFilter} from './common/PriorityFilter';

export const ParentTasksFilter: React.FC<TasksFilterComponentProps> = ({ locationId, children }) => {
  const location = useSelector(selectLocationsByIds)[locationId];
  const canCreateTask = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));
  const isOverdueColumnsFeature = useSelector(selectFeatureFlags)[FeatureFlags.OverdueTaskColumns];
  const isExpirableColumnsFeatureEnabled = useSelector(selectFeatureFlags)[FeatureFlags.TaksExpirable];
  const classes = useTaskFilterStyles();
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const { 
    valuesFiltersTasks,
    handleSetFilterStatus,
  } = useContext(TasksFilterContext);
    const options = useMemo(() => {
      return valuesFiltersTasks?.tasksViewMode !== TasksViewModeMyTasks ? getParentTaskFilterOptions(isOverdueColumnsFeature?.enabled ?? false, isExpirableColumnsFeatureEnabled?.enabled ?? false)
      : getAutomationTaskFilterOptions()
  }, [isOverdueColumnsFeature, isExpirableColumnsFeatureEnabled, valuesFiltersTasks.tasksViewMode]);


  return (
    <>
      <FlexBoxContainer flexDirection='row' flexWrap='wrap' alignItems='center' className={classes.taskContainer}>
        <SimpleTaskListHeader regionName={location?.name} showFullRegionName={false}/>
        <ViewSwitch type={ButtonSwitchType.ParentTasks} />
        <OptionsDropdown
            className={classes.statusFilter}
            selectClasses={{ select: classes.select }}
            unsetValue={allTasksSyntheticValue}
            options={options}
            value={valuesFiltersTasks.filter}
            onValueChange={handleSetFilterStatus}
        />
        {children}
        <FlexBox className={classes.containerLeft}>
          <FilterButton onChange={handleChange} checked={checked}/>
          { canCreateTask && <TasksToReviewButton />}
        </FlexBox>
      </FlexBoxContainer>
      <CollapseBox show={checked}>
        <FlexBox flexDirection='row' flexWrap='wrap' alignItems='center' mt={2}>
          <TasksTagFilter />
          <PriorityFilter type={TypePriorityFilter.TaksList}/>
          <TaskCreatorFilter />
        </FlexBox>
      </CollapseBox>
    </>
  )
}
