import { t } from 'i18next';
import { useCallback, useContext, useMemo } from 'react';
import { TaskPriority } from 'api/task/taskModel';
import { TasksFilterContext } from 'modules/task/taskList/context/taskFilterContext';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { PriorityFilterButton } from '../common/priorityFilter/typePriorityFilter';

export const TasksPriorityFilter: React.FC = () => {
    const isTaskPriorityEnabled = useFeatureState(FeatureFlags.TaskPriority);
    const {
        valuesFiltersTasks,
        handleFilterTaskPriority,
    } = useContext(TasksFilterContext);

    const title = useMemo(()=> {
        switch (valuesFiltersTasks.taskPriority) {
          case TaskPriority.None:
            return `${t('Show low priority tasks')}`;
          case TaskPriority.Low:
            return `${t('Show medium priority tasks')}`;
          case TaskPriority.Medium:
            return `${t('Show high priority tasks')}`;
          case TaskPriority.High:
            return `${t('All tasks regardless of priority')}`;
          default:
            return `${t('Show low priority tasks')}`;
        }
    }, [valuesFiltersTasks])

    const handleFilterTasksPriority = useCallback(() => {
        let newPriority = TaskPriority.None;
        switch (valuesFiltersTasks.taskPriority) {
          case TaskPriority.None:
            newPriority = TaskPriority.Low;
            break;
          case TaskPriority.Low:
            newPriority = TaskPriority.Medium;
            break;
          case TaskPriority.Medium:
            newPriority = TaskPriority.High;
            break;
          case TaskPriority.High:
            newPriority = TaskPriority.None;
            break;
        }
        handleFilterTaskPriority(newPriority);
    },[handleFilterTaskPriority, valuesFiltersTasks]);

    if(!isTaskPriorityEnabled) return <></>;

    return (
        <PriorityFilterButton
          title={title}
          onFilterTasksPriority={handleFilterTasksPriority}
          typePriority={valuesFiltersTasks?.taskPriority ?? TaskPriority.None}
        />
    )
}
