import { TaskTagType } from 'api/task/tag/taskTagModel';
import {
    IHasApprovable,
    IHasCompletionState,
    IHasDueDate,
    IHasExpirable,
    ISkeleton,
    ICreatedAt,
    IPriority
} from '../../../model';

export enum TaskType {
    ScheduledTask = 'scheduledTask',
    AutomationTask = 'automationTask',
    Task = 'task',
    CalendarEvent = 'calendarEvent'
}

export interface ICalendarTaskRowData extends
    ISkeleton,
    IHasDueDate,
    IHasCompletionState,
    IHasApprovable,
    IHasExpirable,
    ICreatedAt,
    IPriority
{
    id: string;
    title: string;
    tag?: null | TaskTagType;
    prototypeId?: string;
    taskType: TaskType
}
