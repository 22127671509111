import { TaskPriority } from 'api/task/taskModel';
import i18n from 'i18n';
import moment from 'moment';
import * as yup from 'yup';

export const TASK_MAX_TITLE_LENGTH = 500;
export const TASK_DATE_FORMAT = 'YYYY-MM-DDT23:59:59';
export const TASK_DATE_FORMAT_INIT = 'YYYY-MM-DDT00:00:00';
export const TASK_DATE_FORMAT_YYYYMMDD = 'YYYY-MM-DD';
export const CHECKLIST_ITEM_MAX_TITLE_LENGTH = 500;

export const titleSchema = yup.string()
    .trim()
    .required(`${i18n.t('Title is a required field')}`)
    .max(TASK_MAX_TITLE_LENGTH)
    .label('Title');

export const descriptionSchema = yup.string()
    .label('Description');

export const recurrenceSchema = yup.string()
    .nullable()
    .label('Recurrence');

export const dateSchema = yup.date()
    .transform((_: Date, originalValue: string) => {
        const mDate = moment(originalValue);
        return mDate.isValid() ? mDate.toDate() : null;
    })
    .required()

export const attachmentSchema = yup.object().shape({
    fileId: yup.string().required(),
    name: yup.string().required(),
    hasThumbnail: yup.boolean().required(),
});

export const checklistItemNameSchema = yup.string()
    .trim()
    .required()
    .max(CHECKLIST_ITEM_MAX_TITLE_LENGTH)
    .label('Checklist item');

export const checklistItemSchema = yup.object().shape({
    name: checklistItemNameSchema,
    requiresAttachment: yup.boolean().required(),
    requiresCustomResponse: yup.boolean().required(),
});

export const tagSchema = yup.object().shape({
    id: yup.string(),
    name: yup.string(),
    color: yup.string(),
}).nullable();

export const colorSchema = yup.string().trim()
    .max(7, `${i18n.t('Must have a maximum of 7 characters')}`)
    .min(4, `${i18n.t('Must have a minimum of 4 characters')}`)
    .matches(/^#(?:[0-9a-fA-F]{3}){1,2}$/, `${i18n.t('Is not in correct format')}`);

export const lightColorSchema  = colorSchema
    .matches(/^#(?:[fF]{3,6}){1,2}$/, `${i18n.t('Select another color')}`)

export const taskPrioritySchema = yup.mixed<TaskPriority>()
    .oneOf(Object.values(TaskPriority), 'Invalid task priority')
    .nullable()
    .defined();
