import * as t from 'io-ts';
import { ParentTaskStatusFilter } from '../parentTask/parentTaskModel';
import { fromEnum } from 'utils/helpers/fromEnum';
import { StoreTaskStatusFilter } from '../storeTask/storeTaskModel';
import { AutomationTaskStatusFilter } from 'api/task/automationTask/automationTaskModel';
import { TaskPriority } from '../taskModel';

export const filterStatusSchema = t.union([t.undefined,
    fromEnum('ParentTaskStatusFilter', ParentTaskStatusFilter),
    fromEnum('StoreTaskStatusFilter', StoreTaskStatusFilter),
    fromEnum('AutomationTaskStatusFilter', AutomationTaskStatusFilter)
]);

export const tasksFiltersSchema = t.type({
    filter: filterStatusSchema,
    tags: t.array(t.string),
    searchTerm: t.string,
    tasksViewMode: t.union([t.undefined, t.string]),
    createdBy: t.union([t.undefined, t.array(t.string)]),
});

export const tasksFilterSchema = t.intersection([
    tasksFiltersSchema,
    t.type({ 
        limit: t.number,
        startDate: t.union([t.undefined, t.string]),
        endDate: t.union([t.undefined, t.string]),
        sortBy: t.string,
        descending: t.boolean,
        taskPriority: t.union([t.undefined, fromEnum('TaskPriority', TaskPriority)])
    })
]);

export type TasksFilterStatusType = t.TypeOf<typeof filterStatusSchema>;
export type TasksFilterType = t.TypeOf<typeof tasksFilterSchema>;
export type TasksFiltersType = t.TypeOf<typeof tasksFiltersSchema>;

export interface TasksFilterUpdate {
    taskFilter: Partial<TasksFilterType>;
    hasFlagEnabled: boolean
}

export const DefaultSortByStoreTask = 'completion-status-store';
export const DefaultSortByParentTask = 'completion-status-parent';
export const DefaultTasksViewMode = 'Default';
export const DefaultStatusFilter: ParentTaskStatusFilter = ParentTaskStatusFilter.Incomplete;
export const DefaultValuesFilter: TasksFilterType = {
    limit: 100,
    filter: DefaultStatusFilter,
    tags: [],
    searchTerm: '',
    startDate: undefined,
    endDate: undefined,
    sortBy: DefaultSortByParentTask,
    descending: false,
    tasksViewMode: DefaultTasksViewMode,
    createdBy: [],
    taskPriority: TaskPriority.None
};

export const TasksViewModeMyTasks = 'MyTasks';
export const TasksViewModeOption = {value: TasksViewModeMyTasks, title:'My Tasks'};

export enum TaskStatusFilterTab {
    DefaultTasksViewMode = 'Default',
    TasksViewModeMyTasks = 'MyTasks',
    ByLocation = 'byLocation',
    ByScheduledTask = 'byScheduledTask',
    CalendarTask = 'CalendarTask'
}
export const DefaultValuesFilterCalendar = {
    filter: undefined,
    tags: [],
};
