import { AutomationTaskStatusFilter } from 'api/task/automationTask/automationTaskModel';
import { createSingleAction } from 'utils/store/actionUtils';

export const CALENDAR_FILTER_TAGS = 'task/calendar/CALENDAR_FILTER_TAGS';
export const calendarFilterTags = createSingleAction<string[], typeof CALENDAR_FILTER_TAGS>(
    CALENDAR_FILTER_TAGS,
);
export const CALENDAR_FILTER_STATUS = 'task/calendar/CALENDAR_FILTER_STATUS';
export const calendarFilterStatus = createSingleAction<AutomationTaskStatusFilter | null, typeof CALENDAR_FILTER_STATUS>(
    CALENDAR_FILTER_STATUS,
);
export const CALENDAR_CALENDAR_FILTER_LOCATION_IDS = 'task/calendar/CALENDAR_FILTER_LOCATION_IDS';
export const calendarFilterLocationIds = createSingleAction<string[], typeof CALENDAR_CALENDAR_FILTER_LOCATION_IDS>(
    CALENDAR_CALENDAR_FILTER_LOCATION_IDS,
);
export const CALENDAR_FILTER_CREATOR = 'task/calendar/CALENDAR_FILTER_CREATOR';
export const calendarFilterCreator = createSingleAction<string[], typeof CALENDAR_FILTER_CREATOR>(
    CALENDAR_FILTER_CREATOR,
);
export const CALENDAR_FILTER_SCHEDULED_TASKS = 'task/calendar/CALENDAR_FILTER_SCHEDULED_TASKS';
export const calendarFilterScheduledTasks = createSingleAction<boolean, typeof CALENDAR_FILTER_SCHEDULED_TASKS>(
    CALENDAR_FILTER_SCHEDULED_TASKS,
);

export const CALENDAR_FILTER_RESET = 'task/calendar/CALENDAR_FILTER_RESET';
export const resetFilterCalendar = createSingleAction<void, typeof CALENDAR_FILTER_RESET>(
    CALENDAR_FILTER_RESET,
);

export const CALENDAR_FILTER_DATA = 'task/calendar/CALENDAR_FILTER_DATA';
export const resetFilterData = createSingleAction<void, typeof CALENDAR_FILTER_DATA>(
    CALENDAR_FILTER_DATA,
);

export const CALENDAR_FILTER_TASKS_EVENTS = 'task/calendar/CALENDAR_FILTER_TASKS_EVENTS';
export const calendarFilterTasksEvents = createSingleAction<void, typeof CALENDAR_FILTER_TASKS_EVENTS>(
    CALENDAR_FILTER_TASKS_EVENTS,
);

export const CALENDAR_FILTER_TASKS_PRIORITY = 'task/calendar/CALENDAR_FILTER_TASKS_PRIORITY';
export const calendarFilterTasksPriority = createSingleAction<void, typeof CALENDAR_FILTER_TASKS_PRIORITY>(
    CALENDAR_FILTER_TASKS_PRIORITY,
);

export type CalendarFilterActions = (
    | ReturnType<typeof calendarFilterLocationIds>
    | ReturnType<typeof resetFilterCalendar>
    | ReturnType<typeof calendarFilterTags>
    | ReturnType<typeof calendarFilterStatus>
    | ReturnType<typeof resetFilterData>
    | ReturnType<typeof calendarFilterCreator>
    | ReturnType<typeof calendarFilterScheduledTasks>
    | ReturnType<typeof calendarFilterTasksEvents>
    | ReturnType<typeof calendarFilterTasksPriority>
);
