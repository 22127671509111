import * as t from 'io-ts';
import { taskTagNewSchema } from '../tag/taskTagModel';
import { isRight } from 'fp-ts/Either';
import { sort } from 'fp-ts/Array';
import { Ord } from 'fp-ts/Ord';
import { Ordering } from 'fp-ts/Ordering';
import { StoresSchema } from 'api/store/storeModel';
import { TaskPriority } from '../taskModel';
import { fromEnum } from 'utils/helpers/fromEnum';

export const checklistItemSchema = t.type({
    id: t.string,
    name: t.string,
    order: t.number,
    requiresAttachment: t.boolean,
    requiresCustomResponse: t.union([t.undefined, t.null, t.boolean]),
});

export type ChecklistItemModel = t.TypeOf<typeof checklistItemSchema>;

export const checklistItemUpdateSchema = t.type({
    id: t.union([t.undefined, t.string]),
    name: t.string,
    order: t.number,
    requiresAttachment: t.boolean,
    requiresCustomResponse: t.union([t.null, t.undefined, t.boolean]),
});

export const attachmentSchema = t.type({
    fileId: t.string,
    name: t.string,
    hasThumbnail: t.boolean,
});

class ChecklistItemsOrder implements Ord<ChecklistItemModel> {
    compare(a: ChecklistItemModel, b: ChecklistItemModel): Ordering {
        return a.order > b.order ? 1 : -1;
    }
    equals(a: ChecklistItemModel, b: ChecklistItemModel): boolean {
        return a.order === b.order;
    }
}

export const taskSortedChecklistSchema = new t.Type<
    Array<ChecklistItemModel>, Array<ChecklistItemModel>, Array<ChecklistItemModel>
>(
    'taskSortedChecklist',
    (s: unknown): s is Array<ChecklistItemModel> => isRight(t.array(checklistItemSchema).decode(s)),
    (param) => t.success(
        sort<ChecklistItemModel>(new ChecklistItemsOrder())(param),
    ),
    value => value,

);

export const taskPrototypeSchema = t.type({
    id: t.string,
    title: t.string,
    description: t.union([t.null, t.string]),
    dueDate: t.string,
    checklistItems: t.array(checklistItemSchema).pipe(taskSortedChecklistSchema),
    attachments: t.array(attachmentSchema),
    locationIds: t.array(t.string),
    watchers: t.array(t.string),
    isExpirable: t.union([t.boolean, t.undefined, t.null]),
    publishDate: t.union([t.undefined,t.string]),
    recurrence: t.union([t.undefined, t.null, t.string]),
    tag: t.union([t.undefined, t.null, taskTagNewSchema]),
    createdBy: t.union([t.undefined, t.string]),
    sendReminder: t.union([t.undefined, t.boolean]),
    locationsFromTemplate: t.union([t.undefined, StoresSchema]),
    priority: t.union([t.undefined, t.string])
});


export const taskPrototypeUdateSchema = t.type({
    id: t.string,
    title: t.string,
    description: t.union([t.null, t.string]),
    dueDate: t.string,
    checklistItems: t.array(checklistItemUpdateSchema),
    attachments: t.array(attachmentSchema),
    watchers: t.array(t.string),
    isExpirable: t.union([t.boolean, t.undefined, t.null]),
    publishDate: t.union([t.undefined,t.string]),
    recurrence: t.union([t.undefined, t.null, t.string]),
    locationIds: t.array(t.string),
    tag: t.union([t.undefined, t.null, taskTagNewSchema]),
    createdBy: t.union([t.undefined, t.string]),
    sendReminder: t.union([t.undefined, t.boolean]),
    priority: t.union([t.undefined, fromEnum('TaskPriority', TaskPriority)])
});

export type TaskPrototype = t.TypeOf<typeof taskPrototypeSchema>;
export type TaskPrototypeUpdate = t.TypeOf<typeof taskPrototypeUdateSchema>;
