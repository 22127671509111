
import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHourglassHalf
} from '@fortawesome/free-solid-svg-icons';
import { BlueColor } from 'utils/styles/constants';
import { Box, Fade, Tooltip } from '@material-ui/core';
import { Trans } from 'react-i18next';

interface ITitleExpiredDateProps {
    showHourGlass?: boolean | null,
    title: string | ReactNode,
    tagList?: ReactNode
}

export const TitleExpiredDate = React.forwardRef<HTMLDivElement, ITitleExpiredDateProps>(({ title, showHourGlass =  false, tagList}, ref) =>
    (
        <Box display="flex">
            {title}
            {tagList ?? tagList}
            {showHourGlass && <Tooltip title={<Trans>Task Expires at Due Date</Trans>} ref={ref} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                <Box width={18} ml={1} display="flex" alignItems="center">
                    <>
                        {showHourGlass && (
                            <FontAwesomeIcon size="1x" icon={faHourglassHalf} color={BlueColor.Blue50}/> 
                            )
                        }
                    </>
                </Box>
            </Tooltip>}
        </Box>
    )
)
