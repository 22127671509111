import React from 'react';
import { FlexBox } from 'modules/shared/components/FlexBox';
import { TasksFilterComponentProps } from '../TypeTaskFilterMap';
import { CollapseBox } from 'modules/connect/messenger/components/ChatHeader/chatActions/InviteAction/CollapseBox';
import { CalendarTagFilter } from './calendar/CalendarTagFilter';
import { CalendarLocationFilter } from './calendar/CalendarLocationFilter';
import { CalendarHeader } from './calendar/CalendarHeader';
import { CalendarCreatorFilter } from './calendar/CalendarCreatorFilter';
import { CalendarScheduledTasksFilter } from './calendar/CalendarScheduledTasksFilter';
import { CalendarTasksEventsFilter } from './calendar/CalendarTasksEventsFilter';
import { PriorityFilterComponent as PriorityFilter, TypePriorityFilter } from './common/PriorityFilter';

export const CalendarParentFilter: React.FC<TasksFilterComponentProps> = ({ locationId }) => {
  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  const handleChangeCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <>
      <CalendarHeader locationId={locationId} handleChangeCollapse={handleChangeCollapse} collapsed={collapsed}/>
      <CollapseBox show={collapsed}>
        <FlexBox flexDirection='row' flexWrap='wrap' alignItems='center' mt={2}>
          <CalendarTagFilter />
          <PriorityFilter type={TypePriorityFilter.Calendar}/>
          <CalendarLocationFilter />
          <CalendarCreatorFilter />
          <CalendarScheduledTasksFilter />
          <CalendarTasksEventsFilter />
        </FlexBox>
      </CollapseBox>
    </>
  )
}
