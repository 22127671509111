import { Box, Dialog, Theme, Typography, useMediaQuery } from '@material-ui/core';
import 'react-image-crop/dist/ReactCrop.css';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Breakpoints } from 'utils/styles/constants';
import { selectLaunchCoverEditorOpened,  } from 'store/modules/launch/launchSelectors';
import { useLaunchCoverStyles } from './styles';
import { ImageEditor } from 'components/CropperImage/ImageEditor';
import { SaveImageCropedType } from 'components/CropperImage/saveImageCroped/SaveImageCropedMap';

export interface ILaunchCoverFormData {
    launchCover: string;
}

export const CropperModal = () => {
    const fullscreen = useMediaQuery((t: Theme) => t.breakpoints.down(Breakpoints.MobileLarge));
    const isOpen = useSelector(selectLaunchCoverEditorOpened);
    const classes = useLaunchCoverStyles();

    return (
        <Dialog
            open={isOpen}
            onClose={() => {}}
            fullScreen={fullscreen}
            maxWidth="lg"
        >
            <Box className={classes.dialogBox}>
                <Box margin={3}>
                    <Typography
                        variant="h3"
                        className={classes.modalCropperTitle}
                    >
                        <Trans>Replace Cover Photo</Trans>
                    </Typography>
                </Box>
                    <Box className={classes.boxInner1}>
                        <Box>
                            <ImageEditor 
                                textPreview={<Trans>Launch cover image Preview</Trans>}
                                acceptFile={['.png', '.jpg', '.jpeg']}
                                isCenterBox={true}
                                typeSaveImageCroped={SaveImageCropedType.LaunchCover}
                                aspect={4}
                            />
                        </Box>
                    </Box>
            </Box>
        </Dialog>
    );
}
