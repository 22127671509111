import { InputAdornment } from '@material-ui/core'
import { TaskPriority as TaskPriorityEnum } from 'api/task/taskModel';
import { useField } from 'formik';
import { PriorityFilterButton } from './taskFilter/filters/common/priorityFilter/typePriorityFilter';
import { t } from 'i18next';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';

const taskPriorityName: string = 'priority';

const getNextPriority = (currentPriority: TaskPriorityEnum): TaskPriorityEnum => {
    switch (currentPriority) {
        case TaskPriorityEnum.None: return TaskPriorityEnum.Low;
        case TaskPriorityEnum.Low: return TaskPriorityEnum.Medium;
        case TaskPriorityEnum.Medium: return TaskPriorityEnum.High;
        case TaskPriorityEnum.High: return TaskPriorityEnum.None;
        default: return TaskPriorityEnum.None;
    }
};

export const TaskPriority = () => {
    const isTaskPriorityEnabled = useFeatureState(FeatureFlags.TaskPriority);
    const [ field ] = useField(taskPriorityName);
    const { onChange, value } = field;

    if(!isTaskPriorityEnabled) return null;

    const handleChangePriority = () => {
        const nextPriority = getNextPriority(value as TaskPriorityEnum);
        onChange({ target: { name: taskPriorityName, value: nextPriority } });
    };

    return (
        <InputAdornment position="start">
            <PriorityFilterButton
                title={`${t('Here you can prioritize the task')}: ${t(value)}`}
                onFilterTasksPriority={handleChangePriority}
                typePriority={value}
            />
        </InputAdornment>
    )
}
