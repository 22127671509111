import React, { createContext, useState, useEffect, useMemo } from 'react';
import { useFiltersTasks } from '../hooks/useFiltersTasks';
import { TasksFilterType } from 'api/task/taskFilter/taskFilterModel';
import { ParentTaskStatusFilter } from 'api/task/parentTask/parentTaskModel';
import { StoreTaskStatusFilter } from 'api/task/storeTask/storeTaskModel';
import { AutomationTaskStatusFilter } from 'api/task/automationTask/automationTaskModel';
import { TaskPriority } from 'api/task/taskModel';

export interface TasksFilterContextProp {
    valuesFiltersTasks: TasksFilterType,
    handleFilterTag: (newValues: string[]) => void,
    handleSetFilterStatus: (newValue?: ParentTaskStatusFilter | StoreTaskStatusFilter | AutomationTaskStatusFilter) => void,
    showFilterTag: boolean,
    showTableTask: boolean,
    handleFilterAutomationTasks: (newValue?: string) => void,
    handleFilterCreatedBy: (newValue: string[]) => void,
    handleFilterTaskPriority: (newPriority: TaskPriority) => void,
}

export const TasksFilterContext = createContext( {} as TasksFilterContextProp );

export const TasksFilterProvider = ( { isStore, children }: { isStore: boolean | null | undefined, children: JSX.Element } ) => {
    
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

    useEffect(()=>{
        if (isFirstRender) {
            setIsFirstRender(false);
        }
    },[isFirstRender]);

    const { 
        handleFilterTag,
        handleSetFilterStatus,
        valuesFiltersTasks,
        showFilterTag,
        showTableTask,
        handleFilterAutomationTasks,
        handleFilterCreatedBy,
        handleFilterTaskPriority
    } = useFiltersTasks(isStore, isFirstRender);

    const taskFilterProviderValues = useMemo(() => ({ 
        handleFilterTag,
        handleSetFilterStatus,
        valuesFiltersTasks,
        showFilterTag,
        showTableTask,
        handleFilterAutomationTasks,
        handleFilterCreatedBy,
        handleFilterTaskPriority
    }), [handleFilterTag, handleSetFilterStatus, handleFilterAutomationTasks, valuesFiltersTasks, showFilterTag, showTableTask, handleFilterCreatedBy, handleFilterTaskPriority]);

    return (
        <TasksFilterContext.Provider value={taskFilterProviderValues}>
            {children}
        </TasksFilterContext.Provider>
    )
}
