import { Skeleton } from '@material-ui/lab';
import { CellProps } from 'react-table';
import { TaskNameCore } from 'modules/task/taskList/components/sharedCells/TaskName';
import { ScheduledTaskShort } from 'api/task/scheduledTask/scheduledTaskModel';
import { ISkeleton } from '../../model';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { generatePath } from 'react-router';
import { Routes } from 'components/router/model';

export const TitleCell: React.FC<CellProps<ScheduledTaskShort & ISkeleton, string>> = ({ row, value }) => {
    const viewScheduledTaskEnabled = useFeatureState(FeatureFlags.ViewScheduledTask);

    if (row.original.isSkeleton) {
        return <Skeleton width="200px" />;
    }

    const { id, isApproval, isExpirable, tag = null } = row.original;
    const editLink = viewScheduledTaskEnabled
        ? generatePath(Routes.ScheduledTaskDetails, { uuid: id })
        : undefined;

    return (
        <TaskNameCore
            title={value}
            link={editLink}
            isApprovable={isApproval}
            isExpirable={isExpirable}
            tag={tag}
            priority={row.original.priority}
        />
    );
};
