import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { calendarEventSchema } from './model';
import { useActions } from "utils/store/useActions";
import { useSelector } from 'react-redux';
import { getPendingFiles, getUploadedFiles } from 'store/modules/files/filesSelectors';
import { selectHierarchy } from 'store/modules/hierarchy/hierarchySelectors';
import { TASK_DATE_FORMAT_INIT } from '../common/form/validation';
import { Routes } from 'components/router/model';
import { generatePath } from 'react-router';
import { formatDateCustom } from 'utils/helpers/dateHelper';
import { FormPageView } from 'modules/shared/components/FormPageView';
import { BackButton } from 'modules/shared/components/BackButton';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import { AddEventForm } from './AddEventForm';
import { createLocationSchema } from '../addTask/model';
import { CreateCalendarEvent } from 'api/task/calendarTask/calendaTaskModel';
import { requestCalendarCreateEvent } from 'api/task/calendarTask/calendaTaskActions';
import { TaskPriority } from 'api/task/taskModel';

function validateForms(pendingFilesCount: number) {
    if (pendingFilesCount > 0) {
        return { attachments: `${i18n.t('To attach files please click on Upload button')}` };
    }
    return {};
}

export function AddEventPage() {
    const pendingFiles = useSelector(getPendingFiles);
    const hierarchy = useSelector(selectHierarchy);
    const uploadedFiles = useSelector(getUploadedFiles);
    const initialFormValues = useMemo(() => ({
        title: '',
        eventStart: formatDateCustom(new Date(), TASK_DATE_FORMAT_INIT),
        eventEnd: '',
        description: '',
        attachments: [],
        locations: [],
        tag: null,
        priority: TaskPriority.None
    }), []);

    const actions = useActions({
        requestCreateEvent: requestCalendarCreateEvent.init,
    });

    const onSubmit = useCallback((values: CreateCalendarEvent) => {
        if (uploadedFiles.length > 0) {
            values.attachments = [...uploadedFiles];
        }
        actions.current.requestCreateEvent(values);
    }, [actions, uploadedFiles]);

    const fullSchema = useMemo(() => {
        const locationSchema = createLocationSchema(hierarchy);

        return yup.object({
            ...calendarEventSchema,
            ...locationSchema,
        });
    }, [hierarchy, ]);

    return (
        <FormPageView
            actionBar={<BackButton route={generatePath(Routes.TaskListCalendar)}><Trans>Back to Calendar</Trans></BackButton>}
        >
            <Formik
                initialValues={initialFormValues}
                validationSchema={fullSchema}
                validate={() => validateForms(pendingFiles.length)}
                onSubmit={onSubmit}
            >
                {props => <AddEventForm handleSubmit={props.handleSubmit} />}
            </Formik>
        </FormPageView>
    );
}
